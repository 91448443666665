.loader{
    &__container{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F1F2F3;
    }

    .loader{
        width: 50px;
    }
}