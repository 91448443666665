@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,body{ min-height: 100vh; }
button,a,[onclick],.pointer{
  cursor: pointer;
}
/* BEGIN:: HANDLE SCROLL =================================== */
::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: theme(colors.gray.200);
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: theme(colors.gray.400);
  border-radius: 20px;
}
/* END:: HANDLE SCROLL | BEGIN:: HANDLE INPUT FILE ========= */
:root {
  --primary: #2b64b3; /* theme(colors.primary.500) */
  --success: theme(colors.green.700);
  --light: theme(colors.gray.200);
  --danger: theme(colors.red.700);
  --warning: theme(colors.yellow.400);
  --info: theme(colors.sky.400);
}
input::file-selector-button {
  background-color: theme(colors.primary-600) !important;
}
input::file-selector-button:hover {
  background-color: theme(colors.primary-700) !important;
}

.custom-flowbite-pills [role="tablist"] > button{
  padding:  0.5rem 0.8rem !important;
}
.custom-flowbite-pills [role="tablist"] > button.bg-blue-600{
  background: theme('colors.primary-600')
}
/* END:: HANDLE INPUT FILE | BEGIN:: UTILS ================= */
.btn-reset-css,
.btn-reset-css:hover,
.btn-reset-css:active,
.btn-reset-css:focus{
  border: 0;
  padding: 0;
  background: transparent;
  margin: 0;
}
.hover-opacity{
  transition: .2s;
  &:hover{ opacity: .7; }
}
.invisible-scroll::-webkit-scrollbar{
  width: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
}
/* END:: UTILS ============================================= */
.App{
  min-height: 100vh;
  overflow-y: auto;
  margin: 0 auto;
  overflow-x: hidden; 
}
button.slick-arrow.slick-next{
  /* gradient */
  height: 100%;
  width: 50px;
  right: 0px !important;
}

button.slick-arrow.slick-prev{
  left: 10px !important;
  z-index: 1;
  width: 50px;
}
.Toastify__toast-container{
  z-index: 99999;
}
.Toastify__toast{
  border-radius: 2rem !important;
  min-height: 0 !important;
  padding-top: 2px;
  padding-bottom: 2px;
}
.Toastify__close-button{
  align-self: center !important;
}
.Toastify__progress-bar{
  height: 2px;
  opacity: 0.5 !important;
}