.accordion{
  & > button{
    color: #fff !important;
    
    &:hover {
      background-color: #fff2 !important;
    }  
  }

  :global button.bg-gray-100{
    background-color: #fff2 !important;
  }
}