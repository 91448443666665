.container_otp {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  justify-content: center;

  &__slot {
    position: relative;
    width: 2.6rem;
    height: 2.6rem;

    &::before {
      content: '';
      padding-top: 100%;
      display: block;
    }

    &__input {
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: transparent;
      overflow: hidden;
      color: #434343;
      font-size: 1.3rem;
border-radius: 7px !important;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }

      &:focus {
        border: none;
        outline: 1px solid rgba(7, 232, 7, 0.393);
      }
    }
  }
}